import type { RegisterOrganizationRequest } from "api/types";

import { FC, useEffect, useState } from "react";
import { useForm, FieldErrors, FieldError } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useLogin } from "useLogin";
import { RegistrationForm } from "./RegistrationForm";
import Table from "components/Table";
import { validateFields, parseErrors } from "./inputValidation";
import { Button } from "@southpolecarbon/climateclick-ui";
import { Card } from "@southpolecarbon/climateclick-ui";
import { FormField } from "components/FormField";
import { HomeButton } from "components/HomeButton";
import { snakeCaseToTitleCase, camelCaseToTitleCase } from "stringFormatters";
import { Tabs } from "components/Tabs";
import { Scope } from "api/types";

import { OrganizationsTopBar } from "./organizations.styled";
import { getOrganizationList, registerOrganization } from "api/organizations";

interface Organization {
  OrganizationId: string;
  Name: string;
  Website: string;
  CompensationStrategy: string;
  ApiKey: string;
  Scopes: Scope[];
}

export interface ListOrganization {
  name: string;
  website: string;
  compensationStrategy: string;
  organizationId: string;
  scopes: string[];
}

const generateRandomId = (length: number) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const id = Array.from({ length }, () => characters[Math.floor(Math.random() * characters.length)]).join("");
  return id;
};

const organizationAlreadyExists = (organizationList: Organization[], formData: RegisterOrganizationRequest) => {
  return organizationList.some(
    (org) =>
      org.Name === formData.Name &&
      org.Website === formData.Website &&
      org.CompensationStrategy === formData.CompensationStrategy
  );
};

const formatCell = (key: string, value: any) => {
  let valueString = String(value);
  if (key === "compensationStrategy") {
    valueString = snakeCaseToTitleCase(valueString);
  } else if (key === "scopes") {
    return value.join(", ");
  }
  if (valueString.length > 30) {
    return valueString.slice(0, 27) + "...";
  }
  return valueString;
};

export const Organizations: FC = () => {
  const credentials = useLogin();

  const [randomId] = useState(generateRandomId(15));
  const [apiKey, setApiKey] = useState("");
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [organizationList, setOrganizationList] = useState<ListOrganization[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegisterOrganizationRequest>({
    defaultValues: {
      Id: randomId,
      Name: "",
      Website: "",
      Email: "",
      CompensationStrategy: "MERCHANT_PAYING",
    },
  });
  const navigate = useNavigate();

  const handleNavigate = (org: Record<any, string>) => {
    return () => {
      navigate(`/organizations/${org.organizationId}`);
    };
  };

  const setErrors = (errors: FieldErrors<RegisterOrganizationRequest>) => {
    for (const [key, value] of Object.entries(errors)) {
      setError(key as keyof RegisterOrganizationRequest, value as FieldError);
    }
  };

  const onSubmit = handleSubmit(async (formData) => {
    const [hasErrors, errors] = validateFields(formData);
    if (hasErrors) {
      setErrors(errors);
      return;
    }
    if (organizationAlreadyExists(organizations, formData)) {
      setApiKey("Organization already exists");
      return;
    }
    try {
      const response = await registerOrganization(credentials, formData);

      setApiKey(response.apiKey);
      if (!organizations.some((org) => org.ApiKey === response.apiKey)) {
        setOrganizations([
          ...organizations,
          {
            Name: formData.Name,
            Website: formData.Website,
            CompensationStrategy: formData.CompensationStrategy,
            ApiKey: response.apiKey,
            OrganizationId: response.organizationId,
            Scopes: response.scopes,
          },
        ]);
      }
      register("Id", { value: generateRandomId(15) });
    } catch (error: any) {
      setErrors(parseErrors(error.message));
      setApiKey("Failed to register organization");
    }
  });

  useEffect(() => {
    const filteredList = organizations
      .filter((org) => org.Name.toLowerCase().includes(filter.toLowerCase()))
      .map((org) => ({
        name: org.Name,
        website: org.Website,
        compensationStrategy: org.CompensationStrategy,
        organizationId: org.OrganizationId,
        scopes: org.Scopes.map((scope) => scope.name),
      }));
    setOrganizationList(filteredList);
  }, [organizations, filter]);

  useEffect(() => {
    const main = async () => {
      try {
        const data = await getOrganizationList(credentials);
        setOrganizations(data);
      } catch (error: any) {
        console.error(error);
      }
    };
    main();
  }, []);

  return (
    <Card>
      <HomeButton />
      <Tabs />
      <OrganizationsTopBar>
        <Button
          $type="secondary"
          onClick={() => setRegistrationModalOpen(true)}
          style={{
            boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
          }}
        >
          Create new
        </Button>
        <div style={{ marginLeft: "auto" }}>
          <FormField placeholder="Filter by name" onChange={(e) => setFilter(e.target.value)} />
        </div>
      </OrganizationsTopBar>
      {registrationModalOpen && (
        <RegistrationForm
          onSubmit={onSubmit}
          register={register}
          setModalState={setRegistrationModalOpen}
          response={apiKey}
          errors={errors}
        />
      )}
      <Table
        data={organizationList}
        emptyMessage="No organizations found"
        omitColumns={["organizationId"]}
        headerFormatter={camelCaseToTitleCase}
        cellFormatter={formatCell}
        onBodyClick={handleNavigate}
      />
    </Card>
  );
};
