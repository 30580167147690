import type { FC } from "react";
import type { UseFormRegister, FieldErrors } from "react-hook-form";

import { Modal, ModalHeader } from "components/Modal";
import { Form } from "components/Form";
import { FormItem } from "components/FormItem";
import { FormField } from "components/FormField";
import { InputError } from "components/InputError";

export interface RegisterUserFormData {
  Name: string;
  Email: string;
}

interface FormProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  register: UseFormRegister<RegisterUserFormData>;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  errors: FieldErrors<RegisterUserFormData>;
}

export const RegistrationForm: FC<FormProps> = ({ onSubmit, register, setModalState, errors }) => {
  return (
    <Modal
      onClose={() => setModalState(false)}
      closeable
      isOpen={true}
      animate
      autoFocus
      size={"responsive"}
      role={"dialog"}
    >
      <ModalHeader>Add Organization User</ModalHeader>
      <Form onSubmit={onSubmit}>
        <FormItem title={"Name"} description="">
          <FormField placeholder="Example" {...register(`Name`, { required: "Name is required" })} />
          {errors.Name && <InputError>{errors.Name.message}</InputError>}
        </FormItem>
        <FormItem title={"Email"} description="">
          <FormField placeholder="email@example.com" {...register(`Email`, { required: "Email is required" })} />
          {errors.Email && <InputError>{errors.Email.message}</InputError>}
        </FormItem>
      </Form>
    </Modal>
  );
};
