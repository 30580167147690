import { InputHTMLAttributes, forwardRef } from "react";
import { useStyletron } from "styletron-react";

export type FormFieldProps = {
  type: string;
};

export const FormField = forwardRef<HTMLInputElement & FormFieldProps, InputHTMLAttributes<HTMLInputElement>>(
  ({ type = "text", ...props }, ref) => {
    const [css] = useStyletron();
    return (
      <input
        {...props}
        type={type}
        ref={ref}
        className={css({
          paddingLeft: "5px",
          cursor: `text`,
          borderRadius: `3px`,
          borderWidth: `1px`,
          borderColor: `#C9C9C9`,
          borderStyle: `solid`,
          height: `38px`,
          overflow: `hidden`,
          width: `100%`,
          background: props.disabled ? `#EAEAEA` : `#fff`,
          ":focus-within": {
            borderColor: `#015597`,
            margin: `-1px`,
            borderWidth: `2px`,
            height: `40px`,
          },
        })}
      />
    );
  }
);

FormField.displayName = "FormField";
