import { useEffect } from "react";
import { useLogin } from "useLogin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchContainer, SearchInput } from "components/SearchContainer";
import { Button } from "@southpolecarbon/climateclick-ui";
import React from "react";
import Table from "components/Table";
import { HomeButton } from "components/HomeButton";
import { TableContainer } from "../cms.styled";
import { Taxonomy } from "../cms.types";
import { camelCaseToTitleCase } from "stringFormatters";
import { getTaxonomies } from "api/cms/taxonomies";

const getTaxonomiesFromSessionStorage = (): Taxonomy[] | undefined => {
  const taxonomies = sessionStorage.getItem("taxonomies");
  if (taxonomies) {
    return JSON.parse(taxonomies);
  }
  return undefined;
};

export const Taxonomies = () => {
  const credential = useLogin();
  const navigate = useNavigate();
  const [taxonomies, setTaxonomies] = useState<Taxonomy[] | undefined>(getTaxonomiesFromSessionStorage());
  const [name, setName] = useState("");

  useEffect(() => {
    const main = async () => {
      const storedTaxonomies = sessionStorage.getItem("taxonomies");
      if (storedTaxonomies) {
        setTaxonomies(JSON.parse(storedTaxonomies));
      } else {
        try {
          const taxonomies = await getTaxonomies(credential, name);
          if (taxonomies.length === 0) {
            return;
          } else {
            setTaxonomies(taxonomies);
            sessionStorage.setItem("taxonomies", JSON.stringify(taxonomies));
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    main();
  }, [credential]);

  const handleNavigate = (taxonomy: Record<any, string>) => {
    return () => {
      navigate(`/cms/taxonomies/${taxonomy.id}`);
    };
  };

  const searchHandler = async () => {
    try {
      const taxonomies = await getTaxonomies(credential, name);
      if (taxonomies.length === 0) {
        setTaxonomies([]);
      } else {
        setTaxonomies(taxonomies);
        sessionStorage.setItem("taxonomies", JSON.stringify(taxonomies));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <TableContainer>
      <HomeButton text="CMS Home" navigateTo="/cms" />
      <div>
        <SearchContainer>
          <SearchInput type="text" id="name" name="name" onChange={handleChange} placeholder="Enter your search" />

          <Button onClick={searchHandler} $type="primary">
            Search
          </Button>
        </SearchContainer>
        {taxonomies?.length && taxonomies.length > 0 ? (
          <Table data={taxonomies || []} onBodyClick={handleNavigate} headerFormatter={camelCaseToTitleCase} />
        ) : (
          "No results found"
        )}
      </div>
    </TableContainer>
  );
};
