import type { FC } from "react";
import type { Portfolio, OrganizationPortfolio as OrgPortfolio } from "api/types";

import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { ModalItemLabel, OrgSectionContainer, OrgSectionHeader } from "./organizations.styled";
import { getPortfolios, getOrganizationPortfolio, setOrganizationPortfolio } from "api/portfolios";
import { useLogin } from "useLogin";
import { Select } from "@southpolecarbon/climateclick-ui";
import { Button } from "@southpolecarbon/climateclick-ui";
import { ModalItem } from "./common";

type OrganizationPortfolioProps = {
  orgID: string;
};

const sleep = (sec: number) => new Promise((r) => setTimeout(r, sec * 1000));

export const OrganizationPortfolio: FC<OrganizationPortfolioProps> = ({ orgID }) => {
  const [portfolios, setPortfolios] = useState<Portfolio[]>();
  const [selectedID, setSelectedID] = useState<string>();
  const [selectedPortfolio, setSelectedPortfolio] = useState<Portfolio>();
  const [orgPortfolio, setOrgPortfolio] = useState<OrgPortfolio>();
  const credential = useLogin();

  useEffect(() => {
    if (!credential) {
      return;
    }
    const fetchPortfolios = async () => {
      const portfolios = await getPortfolios(credential);
      setPortfolios(portfolios);
      const organizationPortfolio = await getOrganizationPortfolio(credential, orgID);
      setOrgPortfolio(organizationPortfolio);
    };
    fetchPortfolios();
  }, [credential]);

  const onAssignPortfolio = async () => {
    if (!credential || !selectedPortfolio) {
      toast.error("Select a portfolio first");
      return;
    }
    try {
      await setOrganizationPortfolio(credential, { portfolioId: selectedPortfolio.id, organizationId: orgID });
      // have to wait for event sourcing, which is 100% async, so just sleep a bit, whatever
      await sleep(2);
      const organizationPortfolio = await getOrganizationPortfolio(credential, orgID);
      setOrgPortfolio(organizationPortfolio);
      toast.success("Portfolio updated");
    } catch (e: unknown) {
      console.error(e);
      toast.error("Select a portfolio first");
    }
  };

  const curPrice = orgPortfolio?.priceCents || orgPortfolio?.defaultPrice;
  const curPriceLabel = curPrice ? curPrice.toString() : "Couldn't retrieve";

  return (
    <OrgSectionContainer>
      <ToastContainer autoClose={5000} position="bottom-center" />
      <OrgSectionHeader>Portfolio</OrgSectionHeader>
      <ModalItem label="Current portfolio name" text={orgPortfolio?.name || "N/A"} />
      <ModalItem label="Current footprint price" text={curPriceLabel} />
      <ModalItemLabel>Assign portfolio </ModalItemLabel>
      <Select
        placeholder="Assign portfolio"
        changeSelection={(id) => {
          setSelectedID(id);
          setSelectedPortfolio(portfolios?.find((p) => p.id === id));
        }}
        autoFocus={false}
        options={
          portfolios?.map((p) => {
            return { id: p.id, label: p.name };
          }) ?? []
        }
        value={selectedID}
      />
      <Button $type="primary" $size="small" onClick={onAssignPortfolio}>
        Assign portfolio
      </Button>
    </OrgSectionContainer>
  );
};
