import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { BaseProvider } from "baseui";
import { ClimateClickTheme } from "./theme";
import { ThemeProvider, engine } from "@southpolecarbon/climateclick-ui";

const debug = process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider debugEngine={debug}>
      <StyletronProvider value={engine} debug={debug} debugAfterHydration>
        <BaseProvider theme={ClimateClickTheme}>
          <App />
        </BaseProvider>
      </StyletronProvider>
    </ThemeProvider>
  </React.StrictMode>
);
