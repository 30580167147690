import type { FC } from "react";
import type { User, Users, CreateUserRequest } from "api/types";
import type { RegisterUserFormData } from "./UserRegistrationForm";

import { useState, useMemo } from "react";
import { useForm, FieldErrors, FieldError } from "react-hook-form";
import { Button } from "@southpolecarbon/climateclick-ui";
import Table from "components/Table";
import { camelCaseToTitleCase } from "stringFormatters";
import { RegistrationForm } from "./UserRegistrationForm";
import { ValidationError } from "api/errors";

import { parseErrors, validateFields } from "./inputValidation";
import { OrgSectionContainer, OrgSectionHeader } from "./organizations.styled";
import { ToasterContainer, toaster } from "baseui/toast";

interface OrganizationUserProps {
  orgID: string;
  users: Users;
  removeUser: (orgID: string, userID: string) => Promise<void>;
  addUser: (orgID: string, userData: CreateUserRequest) => Promise<void>;
  resendInvite: (userID: string) => Promise<void>;
}

interface TableRow extends User {
  Remove: React.ReactNode;
  ResendInvite: React.ReactNode;
}

const userAlreadyExists = (users: User[], formData: RegisterUserFormData) => {
  return users.some((user) => user.Email === formData.Email);
};

export const OrganizationUsers: FC<OrganizationUserProps> = ({ orgID, users, addUser, removeUser, resendInvite }) => {
  const [orgUsers, setOrgUsers] = useState<User[]>(users.Users);
  const [registrationFormOpen, setRegistrationFormOpen] = useState<boolean>(false);

  const onRemoveUser = async (orgID: string, userID: string) => {
    await removeUser(orgID, userID);
    setOrgUsers(orgUsers.filter((val) => val.ID !== userID));
  };

  const tableData = useMemo<TableRow[]>(() => {
    return orgUsers.map((user) => {
      const row = user as TableRow;
      row.ResendInvite = (
        <div
          onClick={() => {
            if (confirm(`This will resend the password creation/reset link to <${row.Email}>`)) {
              resendInvite(row.ID);
            }
          }}
        >
          📧
        </div>
      );
      if (users.ReadOnly === false) {
        row.Remove = (
          <div
            onClick={() => {
              if (confirm(`Are you sure you want to remove user "${row.Name}" <${row.Email}>?`)) {
                onRemoveUser(orgID, row.ID);
              }
            }}
          >
            ❌
          </div>
        );
      }
      return row;
    });
  }, [orgUsers]);

  const cellFormatter = (key: string, value: any, _row: Record<string, any>) => {
    switch (key) {
      case "Remove":
      case "ResendInvite":
        return value;
      default:
        return String(value);
    }
  };

  const omitCols = ["ID"];
  if (users.ReadOnly === true) {
    omitCols.push("Remove");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegisterUserFormData>({
    defaultValues: {
      Name: "",
      Email: "",
    },
  });

  const setErrors = (errors: FieldErrors<RegisterUserFormData>) => {
    for (const [key, value] of Object.entries(errors)) {
      setError(key as keyof RegisterUserFormData, value as FieldError);
    }
  };

  const onSubmit = handleSubmit(async (formData: RegisterUserFormData) => {
    const [hasErrors, errors] = validateFields(formData);
    if (hasErrors) {
      setErrors(errors);
      return;
    }
    if (userAlreadyExists(orgUsers, formData)) {
      toaster.negative("User with that email already exists");
      return;
    }
    try {
      await addUser(orgID, formData);
    } catch (e) {
      if (e instanceof ValidationError) {
        setErrors(parseErrors(e));
        return;
      }
      toaster.negative("Failed to add user");
      return;
    }
    const now = new Date().toISOString();
    setOrgUsers([
      ...orgUsers,
      {
        ID: "N/A",
        Name: formData.Name,
        Email: formData.Email,
        AddedAt: now,
        CreatedAt: now,
      },
    ]);
    setRegistrationFormOpen(false);
  });

  return (
    <OrgSectionContainer>
      <OrgSectionHeader>Users</OrgSectionHeader>
      <Button $type="primary" $size="small" onClick={() => setRegistrationFormOpen(true)}>
        Add User
      </Button>
      {registrationFormOpen && (
        <RegistrationForm
          onSubmit={onSubmit}
          register={register}
          setModalState={setRegistrationFormOpen}
          errors={errors}
        />
      )}
      <Table
        data={tableData}
        emptyMessage="No users for this organization"
        omitColumns={omitCols}
        headerFormatter={camelCaseToTitleCase}
        cellFormatter={cellFormatter}
      />
      <ToasterContainer autoHideDuration={3000} placement={"top"}></ToasterContainer>
    </OrgSectionContainer>
  );
};
