import type { Product, GameRound } from "api/types";

import { Card, CardContents, CardDivider } from "./components/Card";
import { useLogin } from "./useLogin";
import { useEffect, useState } from "react";
import { HomeButton } from "./components/HomeButton";
import { Select } from "@southpolecarbon/climateclick-ui";
import Table from "./components/Table";
import { TaxonomyUpdater } from "./components/TaxonomyUpdater";
import { Tabs } from "./components/Tabs";
import { useNavigate } from "react-router-dom";
import { getDisapprovals, getGameRounds } from "api/penguinInTheLoop";

const fieldsToOmit = [
  "Id",
  "Url",
  "Description",
  "Ean",
  "ImageUrls",
  "RandomIndex",
  "WeightInGrams",
  "UnitPriceInCents",
  "Currency",
  "ImportDate",
  "HallucinatedCategory",
  "AgribalyseEmissionFactorId",
  "Materials",
  "MatchedProductId",
  "CarbonDropEmissionFactorId",
  "OrganizationId",
  "SanitizedName",
  "TaxonomyLevel",
  "P17pApprovals",
  "P17pDisapprovals",
  "P17pSkips",
  "SecondaryTaxonomy",
  "Brand",
  "Category",
];

export const Disapprovals = () => {
  const credential = useLogin();
  const [products, setProducts] = useState<Product[]>();
  const [gameRounds, setGameRounds] = useState<GameRound[]>();
  const [currentRoundID, setCurrentRoundID] = useState("");
  const navigate = useNavigate();

  const cellFormatter = (key: string, value: any, row: Record<string, any>) => {
    switch (key) {
      case "Taxonomy":
        return (
          <TaxonomyUpdater
            roundID={currentRoundID}
            productId={row.Id}
            taxonomy={row.Taxonomy}
            approved={row.ProductApprovals > 0}
          />
        );
      case "ProductApprovals":
        if (row.ProductApprovals > 0) {
          return "✅";
        } else {
          return "❌";
        }
      default:
        return (
          <div
            onClick={() => {
              navigate(`/product/${row.Id}`, { state: { roundID: currentRoundID } });
            }}
          >
            {value}
          </div>
        );
    }
  };

  const headerFormatter = (key: string): string => {
    switch (key) {
      case "ProductApprovals":
        return "Approved";
      default:
        return key;
    }
  };

  useEffect(() => {
    const main = async () => {
      try {
        const rounds = await getGameRounds(credential);
        setGameRounds(rounds);
      } catch (e) {
        console.error(e);
        return;
      }
    };
    main();
  }, [credential]);

  useEffect(() => {
    if (gameRounds) {
      setCurrentRoundID(gameRounds[gameRounds.length - 1].id);
    }
  }, [gameRounds]);

  useEffect(() => {
    const main = async () => {
      const disapprovals = await getDisapprovals(credential, currentRoundID);
      setProducts(disapprovals);
    };
    main();
  }, [currentRoundID]);

  return (
    <Card>
      <HomeButton />
      <Tabs />
      <CardDivider />
      <CardContents>
        <Select
          options={
            gameRounds
              ?.map((round) => ({
                id: round.id,
                label: `${round.name} | ${round.start.slice(0, 10)} - ${round.end.slice(0, 10)}`,
              }))
              .concat([{ id: "default", label: "Default" }]) ?? []
          }
          placeholder="Select game round"
          value={currentRoundID.length ? currentRoundID : "Default"}
          changeSelection={(id: string) => {
            setCurrentRoundID(id);
          }}
        />
        <h3>Disapproved matchings for {gameRounds?.find((round) => round.id === currentRoundID)?.name}</h3>
        {products !== undefined && (
          <Table
            data={products}
            omitColumns={fieldsToOmit}
            filterColumn="Name"
            cellFormatter={cellFormatter}
            headerFormatter={headerFormatter}
            displayItemCount={true}
          />
        )}
      </CardContents>
    </Card>
  );
};
