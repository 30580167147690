import { FC } from "react";
import { styled } from "styletron-react";
import { Button } from "@southpolecarbon/climateclick-ui";

const FormContainer = styled("form", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "10px",
  padding: "10px",
});

export interface FormProps {
  children: React.ReactNode;
  buttonText?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const Form: FC<FormProps> = ({ children, buttonText = "Submit", onSubmit }) => {
  return (
    <FormContainer onSubmit={onSubmit}>
      {children}
      <Button $type="primary" $size="small" type="submit">
        {buttonText}
      </Button>
    </FormContainer>
  );
};
