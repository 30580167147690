import type { Product } from "api/types";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, CardContents } from "@southpolecarbon/climateclick-ui";
import { useLogin } from "./useLogin";
import { Typography, Button, Form, Badge, Space, Select } from "antd";
import { taxonomies } from "./taxonomies";
import { getProduct } from "api/products";
import { updateProductTaxonomy, approveProductTaxonomy } from "api/penguinInTheLoop";
import { ToasterContainer, toaster } from "baseui/toast";

export const ProductDetailPage = () => {
  const { productId } = useParams();
  const credential = useLogin();
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const roundID = location.state?.roundID;

  const onFinish = async (event: { taxonomy: string }) => {
    const { taxonomy } = event;
    try {
      await updateProductTaxonomy(credential, productId!, taxonomy, roundID);
    } catch (e) {
      console.error(e);
      onApproveOrUpdate("update", false);
      return;
    }
    onApproveOrUpdate("update", true);
  };

  const onClick = async () => {
    try {
      await approveProductTaxonomy(credential, productId!, roundID);
    } catch (e) {
      console.error(e);
      onApproveOrUpdate("update", false);
      return;
    }
    onApproveOrUpdate("approve", true);
  };

  const onApproveOrUpdate = (change: "update" | "approve", success: boolean) => {
    if (!success) {
      toaster.negative(`Failed to ${change} taxonomy.`, {});
      return;
    }
    toaster.positive(`Taxonomy ${change}d. Returning to disapproval overview.`, {});
    setTimeout(() => {
      navigate("/disapprovals");
    }, 1000);
  };

  useEffect(() => {
    if (!productId) {
      return;
    }

    const loadProduct = async () => {
      try {
        const product = await getProduct(credential, productId);
        setProduct(product);
      } catch (e) {
        console.error(e);
        return;
      }
    };
    loadProduct();
  }, [productId, credential]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      <CardContents>
        <Space>
          {product.P17pApprovals! > 0 && <Badge status="success" offset={[0, 12]} />}
          {product.P17pDisapprovals! > 0 && <Badge status="error" offset={[0, 12]} />}
          <Typography.Title level={3}>{product.Name}</Typography.Title>
        </Space>

        <Form.Item label="Approvals / Disapprovals">
          <Typography.Text>
            {product.P17pApprovals} / {product.P17pDisapprovals}
          </Typography.Text>
        </Form.Item>

        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ taxonomy: product.Taxonomy }}
          onFinish={onFinish}
          layout="horizontal"
        >
          <Form.Item label="Name">
            <Typography.Text>{product.Name}</Typography.Text>
          </Form.Item>

          <Form.Item label="English Name">
            {product.TranslatedName ? (
              <Typography.Text>{product.TranslatedName}</Typography.Text>
            ) : (
              <Typography.Text>N/A</Typography.Text>
            )}
          </Form.Item>

          <Form.Item label="Url">
            <Typography.Text>
              <a href={product.Url} target="_blank" rel="noopener noreferrer">
                {product.Url}
              </a>
            </Typography.Text>
          </Form.Item>

          <Form.Item label="Category">
            <Typography.Text>{product.Category}</Typography.Text>
          </Form.Item>

          <Form.Item label="Brand">
            <Typography.Text>{product.Brand}</Typography.Text>
          </Form.Item>

          <Form.Item label="EAN">
            <Typography.Text>{product.Ean}</Typography.Text>
          </Form.Item>

          <Form.Item label="Images">
            <Typography.Text>{product.ImageUrls?.join(", ")}</Typography.Text>
          </Form.Item>

          <Form.Item label="Weight">
            {product.WeightInGrams! > 0 && <Typography.Text>{product.WeightInGrams} gr</Typography.Text>}
            {product.WeightInGrams! <= 0 && <Typography.Text>N/A</Typography.Text>}
          </Form.Item>

          <Form.Item label="Price">
            {product.UnitPriceInCents && product.Currency && (
              <Typography.Text>
                {new Intl.NumberFormat(navigator.language, {
                  style: `currency`,
                  currency: product.Currency,
                  currencyDisplay: `symbol`,
                }).format(product.UnitPriceInCents / 100)}
              </Typography.Text>
            )}
          </Form.Item>

          <Form.Item label="Taxonomy" name="taxonomy">
            <Select
              showSearch
              placeholder="Select a taxonomy"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              options={taxonomies.map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="link" onClick={onClick}>
              Approve taxonomy
            </Button>
            <Button type="primary" htmlType="submit">
              Change taxonomy
            </Button>
          </Form.Item>
        </Form>
        <ToasterContainer autoHideDuration={3000} placement={"bottomRight"}></ToasterContainer>
      </CardContents>
    </Card>
  );
};
