import type { FC } from "react";

import { ModalItemContainer, ModalItemLabel, ModalItemText } from "./organizations.styled";

export const ModalItem: FC<{ text: string; label: string }> = ({ text, label }) => {
  return (
    <ModalItemContainer>
      <ModalItemLabel>{label}: </ModalItemLabel>
      <ModalItemText>{text}</ModalItemText>
    </ModalItemContainer>
  );
};
