import { Center } from "./components/Card";
import { Logo } from "./components/Logo";
import { useNavigate } from "react-router-dom";
import { Button, FlexContainer } from "@southpolecarbon/climateclick-ui";

export const Landing = () => {
  const navigate = useNavigate();

  return (
    <>
      <Center $style={{ textAlign: `center` }}>
        <div>
          <Logo />
        </div>
      </Center>
      <FlexContainer $direction="column" $gap="20px" $style={{ height: "40vh" }}>
        <Button
          $size="large"
          onClick={() => {
            navigate("/penguin-in-the-loop");
          }}
        >
          Penguin in the Loop
        </Button>
        <Button
          $size="large"
          onClick={() => {
            navigate("/productsLookup");
          }}
        >
          Copilot
        </Button>
      </FlexContainer>
    </>
  );
};
