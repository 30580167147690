import type { FC } from "react";
import type { Row, TableProps } from "./Table";

import { useEffect, useState } from "react";
import { Pagination } from "antd";
import { styled } from "styletron-react";

import Table from "./Table";
import EmptyTable from "./EmptyTable";

interface PaginatedTableProps extends Partial<TableProps> {
  getData: (page: number, pageSize?: number) => Promise<Row[]>;
  total?: number;
  dataContentName?: string;
}

const TableContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
});

const PaginatedTable: FC<PaginatedTableProps> = ({ getData, total = 0, dataContentName = "rows", ...tableProps }) => {
  const [page, setPage] = useState(1);
  const [pageContent, setPageContent] = useState<Row[]>([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const main = async () => {
      if (page <= 0) {
        return;
      }
      const data = await getData(page - 1, pageSize);
      setPageContent(data);
    };
    main();
  }, [page, pageSize]);

  if (total === 0) {
    return <EmptyTable message={tableProps.emptyMessage} />;
  }

  return (
    <TableContainer>
      <Pagination
        total={total}
        pageSize={pageSize}
        showQuickJumper
        showTotal={(total) => `Showing ${pageContent.length} ${dataContentName} out of ${total}`}
        onChange={(currentPage, pageSize) => {
          setPage(currentPage);
          setPageSize(pageSize);
        }}
        current={page}
      />
      <Table {...tableProps} data={pageContent} />
    </TableContainer>
  );
};

export default PaginatedTable;
