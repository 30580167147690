import type {
  CreatePortfolioRequest,
  SetOrganizationPortfolioRequest,
  UpdatePortfolioRequest,
  OrganizationPortfolio,
} from "api/types";
import type { Portfolio } from "./types";

import { makeCopilotRequest } from "./common";

export const getPortfolios = async (credential: string) => {
  const response = await makeCopilotRequest("Portfolios", "GET", credential);
  return response as Portfolio[];
};

export const createPortfolio = async (credential: string, req: CreatePortfolioRequest) => {
  const response = await makeCopilotRequest("Portfolios", "POST", credential, req);
  return response as Portfolio;
};

export const updatePortfolio = async (credential: string, req: UpdatePortfolioRequest) => {
  return await makeCopilotRequest("Portfolios", "PUT", credential, req);
};

export const setOrganizationPortfolio = async (credential: string, req: SetOrganizationPortfolioRequest) => {
  return await makeCopilotRequest(`organization/${req.organizationId}/portfolio`, "POST", credential, req);
};

export const getOrganizationPortfolio = async (credential: string, organizationId: string) => {
  const response = await makeCopilotRequest(`organization/${organizationId}/portfolio`, "GET", credential);
  return response as OrganizationPortfolio;
};
