import { useNavigate } from "react-router-dom";
import { HomeButton } from "../../components/HomeButton";
import { Button } from "@southpolecarbon/climateclick-ui";
import { CMSHomeContainer } from "./cms.styled";

export const CMS = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HomeButton />
      <CMSHomeContainer>
        <h1>CMS</h1>
        <Button $type="primary" style={{ width: "15vw" }} onClick={() => navigate("/cms/taxonomies")}>
          Taxonomies
        </Button>
        <Button $type="primary" style={{ width: "15vw" }} onClick={() => navigate("/cms/categories")}>
          Categories
        </Button>
        <Button $type="primary" style={{ width: "15vw" }} onClick={() => navigate("/cms/coverage")}>
          Coverage
        </Button>
        <Button $type="primary" style={{ width: "15vw" }} onClick={() => navigate("/cms/customerProducts")}>
          Customer Products
        </Button>
      </CMSHomeContainer>
    </div>
  );
};
