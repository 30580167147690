import type { FC } from "react";
import type { RegisterOrganizationRequest } from "api/types";
import { UseFormRegister, FieldErrors } from "react-hook-form";

import { Modal, ModalHeader } from "components/Modal";
import { Form } from "components/Form";
import { Response } from "components/Response";
import { FormItem } from "components/FormItem";
import { RadioButton } from "components/RadioButton";
import { Checkbox, FlexContainer } from "@southpolecarbon/climateclick-ui";
import { CardDivider } from "@southpolecarbon/climateclick-ui";
import { FormField } from "components/FormField";
import { InputError } from "components/InputError";

const scopes = [
  {
    value: "PRODUCT",
    title: "Product",
    description:
      "Allows access to the product footprint and compensation endpoints version 1. It also supports fixed amounts contributions.",
  },
  {
    value: "PRODUCT_IMPORT",
    title: "Product import",
    description:
      "Allows access to the product import endpoints version 1. Ideally, there should be no new clients getting this scope. Preference is to support them with V2.",
  },
  {
    value: "ROAD_TRIP",
    title: "Road trip",
    description: "Allows access to the road trip footprint and compensation endpoints",
  },
  { value: "FLIGHT", title: "Flight", description: "Allows access to the flight footprint and compensation endpoints" },
  {
    value: "ACCOMMODATION",
    title: "Accomodation",
    description: "Allows access to the accommodation footprint and compensation endpoints",
  },
  {
    value: "PRODUCT_V2",
    title: "V2 Product",
    description: "Allows access to the V2 API product footprint and compensation endpoints",
  },
  {
    value: "PRODUCT_IMPORT_V2",
    title: "V2 Product import",
    description: "Allows access to the V2 API product creation endpoints",
  },
];

interface RegistrationFormProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  register: UseFormRegister<RegisterOrganizationRequest>;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  response: string;
  errors: FieldErrors<RegisterOrganizationRequest>;
}

export const RegistrationForm: FC<RegistrationFormProps> = ({
  onSubmit,
  register,
  setModalState,
  response,
  errors,
}) => {
  return (
    <Modal
      onClose={() => setModalState(false)}
      closeable
      isOpen={true}
      animate
      autoFocus
      size={"responsive"}
      role={"dialog"}
    >
      <ModalHeader>Register Organization</ModalHeader>
      <Form onSubmit={onSubmit}>
        <FormItem title={"Name"} description="">
          <FormField placeholder="Example" {...register(`Name`, { required: "Name is required" })} />
          {errors.Name && <InputError>{errors.Name.message}</InputError>}
        </FormItem>
        <FormItem title={"Website"} description="">
          <FormField placeholder="https://example.com" {...register(`Website`, { required: "Website is required" })} />
          {errors.Website && <InputError>{errors.Website.message}</InputError>}
        </FormItem>
        <FormItem title={"Email"} description="">
          <FormField placeholder="email@example.com" {...register(`Email`, { required: "Email is required" })} />
          {errors.Email && <InputError>{errors.Email.message}</InputError>}
        </FormItem>
        <FormItem title={"Compensation Strategy"} description="">
          <RadioButton title={"Merchant Paying"} value="MERCHANT_PAYING" {...register(`CompensationStrategy`)} />
          <RadioButton title={"Customer Paying"} value="CUSTOMER_PAYING" {...register(`CompensationStrategy`)} />
        </FormItem>
        <CardDivider style={{ marginBottom: "10px" }} />
        <FormItem title={"API Scopes"} description="">
          {scopes.map(({ title, value }) => {
            return (
              <FlexContainer key={title} $gap={"5px"}>
                {title}
                <Checkbox key={value} title={title} value={value} {...register(`Scopes`)} />{" "}
              </FlexContainer>
            );
          })}
          {errors.Scopes && <InputError>{errors.Scopes.message}</InputError>}
        </FormItem>
      </Form>
      <Response response={response} title={"API Key"}></Response>
    </Modal>
  );
};
