import { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { styled, useStyletron } from "styletron-react";

export const TabContainer = styled(`div`, {
  padding: `20px 20px 0 20px`,
});

export const Tab = styled(`div`, {
  display: `flex`,
  gap: `20px`,
  marginBottom: `-1px`,
});

const NavTab: FC<{ to: string; children: ReactNode }> = ({ to, children }) => {
  const [css] = useStyletron();

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        css({
          display: `inline-block`,
          textDecoration: `none`,
          fontSize: `16px`,
          borderBottom: isActive ? `2px solid #015597` : `0`,
          padding: `0 0 10px 0`,
          color: isActive ? `#015597` : `#A4A4A4`,
          fontWeight: `600`,
          ":hover": {
            color: `#015597`,
            fontWeight: `600`,
          },
        })
      }
    >
      {children}
    </NavLink>
  );
};

export const InlineTab = styled("div", (props: any) => ({
  display: `inline-block`,
  textDecoration: `none`,
  fontSize: `16px`,
  borderBottom: props.$isActive ? `2px solid #015597` : `0`,
  padding: `0 0 10px 0`,
  color: props.$isActive ? `#015597` : `#A4A4A4`,
  fontWeight: `600`,
  cursor: `pointer`,
  ":hover": {
    color: `#015597`,
    fontWeight: `600`,
  },
}));

export const Tabs = () => (
  <TabContainer>
    <Tab>
      <NavTab to="/productsLookup">Products</NavTab>
      <NavTab to="/disapprovals">Disapprovals</NavTab>
      <NavTab to="/taxonomyReport">Taxonomy Report</NavTab>
      <NavTab to="/organizations">Demo Organizations</NavTab>
      <NavTab to="/allOrganizations">All Organizations</NavTab>
      <NavTab to="/coverage">Coverage</NavTab>
      <NavTab to="/invoicing">Invoicing</NavTab>
      <NavTab to="/sectorialScopes">Sectorial Scopes</NavTab>
      <NavTab to="/portfolios">Portfolios</NavTab>
    </Tab>
  </TabContainer>
);
