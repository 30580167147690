import type { FC } from "react";
import type { UseFormRegister, FieldErrors } from "react-hook-form";

import { useState } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { Form } from "components/Form";
import { FormField } from "components/FormField";
import { FormItem } from "components/FormItem";
import { InputError } from "components/InputError";

export interface NewRetirementFormData {
  quantity?: number;
  serialNumbers?: string;
  date?: string;
  beneficialOwner?: string;
  retirementReasonDetails?: string;
  publicUrl?: string;
  projectName?: string;
  projectCountry?: string;
}

interface FormProps {
  onSubmit: (data: NewRetirementFormData) => Promise<void>;
  register: UseFormRegister<NewRetirementFormData>;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  errors: FieldErrors<NewRetirementFormData>;
}

const sampleRetirement = `Quantity of Retired VCU: 20,939
Serial Numbers: 12789-447976466-447997404-VCS-VCU-259-VER-CD-14-934-01012020-31122020-1
Date of Retirement: 14/12/2023 03:38:48 PM
Beneficial Owner: FCM on behalf of its client
Retirement Reason Details: Retired on behalf of FCM's client to compensate for their business travel in Q2&Q3 2023
Public URL: https://registry.verra.org/myModule/rpt/myrpt.asp?r=206&h=200446

The Mai Ndombe REDD+ Project Project type: PRO
Project Country/Area: Congo, The Democratic Republic of The`;

export const OrganizationRetirementForm: FC<FormProps> = ({ onSubmit, register, setModalState, errors }) => {
  const [quantity, setQuantity] = useState(0);
  const [serialNumbers, setSerialNumbers] = useState("");
  const [date, setDate] = useState("");
  const [beneficialOwner, setBeneficialOwner] = useState("");
  const [retirementReasonDetails, setRetirementReasonDetails] = useState("");
  const [publicUrl, setPublicUrl] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectCountry, setProjectCountry] = useState("");

  const handleInput = (e: any) => {
    const value = e.target.value as string;
    if (value.length < 100) {
      return;
    }

    for (const line of value.split(/\r?\n/)) {
      const idx = line.indexOf("Project type:");
      if (idx > -1) {
        setProjectName(line.substring(0, idx).trim());
        continue;
      }

      if (line.includes("Quantity of Retired VCU:")) {
        setQuantity(parseInt(line.replace("Quantity of Retired VCU:", "").trim().replace(",", "")));
      } else if (line.includes("Serial Numbers:")) {
        setSerialNumbers(line.replace("Serial Numbers:", "").trim());
      } else if (line.includes("Date of Retirement:")) {
        setDate(line.replace("Date of Retirement:", "").trim());
      } else if (line.includes("Beneficial Owner:")) {
        setBeneficialOwner(line.replace("Beneficial Owner:", "").trim());
      } else if (line.includes("Retirement Reason Details:")) {
        setRetirementReasonDetails(line.replace("Retirement Reason Details:", "").trim());
      } else if (line.includes("Public URL:")) {
        setPublicUrl(line.replace("Public URL:", "").trim());
      } else if (line.includes("Project Country/Area:")) {
        setProjectCountry(line.replace("Project Country/Area:", "").trim());
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = {
      quantity,
      serialNumbers,
      date,
      beneficialOwner,
      retirementReasonDetails,
      publicUrl,
      projectName,
      projectCountry,
    };
    await onSubmit(formData);
  };

  return (
    <Modal
      onClose={() => setModalState(false)}
      closeable
      isOpen={true}
      animate
      autoFocus
      size={"responsive"}
      role={"dialog"}
    >
      <ModalHeader>Add retirement for an organization</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <FormItem
          title={"Retirement Content"}
          description="Copy paste content from the Verra email, example is given in the text area placeholder. The form items will be updated automatically. Please double check before submitting."
        >
          <textarea onInput={handleInput} placeholder={sampleRetirement} rows={15} cols={80} required />
        </FormItem>
        <FormItem title={"Quantity"}>
          <FormField
            value={quantity}
            {...register(`quantity`, {
              onChange: (e: any) => {
                setQuantity(parseInt(e.target.value));
              },
            })}
          />
          {errors.quantity && <InputError>{errors.quantity.message}</InputError>}
        </FormItem>
        <FormItem title={"Serial Numbers"}>
          <FormField
            value={serialNumbers}
            {...register(`serialNumbers`, {
              onChange: (e: any) => {
                setSerialNumbers(e.target.value);
              },
            })}
          />
          {errors.serialNumbers && <InputError>{errors.serialNumbers.message}</InputError>}
        </FormItem>
        <FormItem title={"Date"}>
          <FormField
            value={date}
            {...register(`date`, {
              onChange: (e: any) => {
                setDate(e.target.value);
              },
            })}
          />
          {errors.date && <InputError>{errors.date.message}</InputError>}
        </FormItem>
        <FormItem title={"Beneficial Owner"}>
          <FormField
            value={beneficialOwner}
            {...register(`beneficialOwner`, {
              onChange: (e: any) => {
                setBeneficialOwner(e.target.value);
              },
            })}
          />
          {errors.beneficialOwner && <InputError>{errors.beneficialOwner.message}</InputError>}
        </FormItem>
        <FormItem title={"Retirement Reason Details"}>
          <FormField
            value={retirementReasonDetails}
            {...register(`retirementReasonDetails`, {
              onChange: (e: any) => {
                setRetirementReasonDetails(e.target.value);
              },
            })}
          />
          {errors.retirementReasonDetails && <InputError>{errors.retirementReasonDetails.message}</InputError>}
        </FormItem>
        <FormItem title={"Public URL"}>
          <FormField
            value={publicUrl}
            {...register(`publicUrl`, {
              onChange: (e: any) => {
                setPublicUrl(e.target.value);
              },
            })}
          />
          {errors.publicUrl && <InputError>{errors.publicUrl.message}</InputError>}
        </FormItem>
        <FormItem title={"Project Name"}>
          <FormField
            value={projectName}
            {...register(`projectName`, {
              onChange: (e: any) => {
                setProjectName(e.target.value);
              },
            })}
          />
          {errors.projectName && <InputError>{errors.projectName.message}</InputError>}
        </FormItem>
        <FormItem title={"Project Country"}>
          <FormField
            value={projectCountry}
            {...register(`projectCountry`, {
              onChange: (e: any) => {
                setProjectCountry(e.target.value);
              },
            })}
          />
          {errors.projectCountry && <InputError>{errors.projectCountry.message}</InputError>}
        </FormItem>
      </Form>
    </Modal>
  );
};
