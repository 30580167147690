import type { Invoice } from "api/types";

import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { useLogin } from "useLogin";
import { createInvoice } from "api/invoice";
import { InvoicingForm, FormField, FormFieldLabel, FormFieldInputContainer, FormFieldError } from "./invoicing.styled";
import { Card, CardContents, CardDivider } from "components/Card";
import { HomeButton } from "components/HomeButton";
import { Tabs } from "components/Tabs";
import { Response } from "components/Response";
import { Button } from "@southpolecarbon/climateclick-ui";

export const Invoicing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Invoice>();
  const [invoiceLink, setInvoiceLink] = useState<string>("");
  const credential = useLogin();
  const searchParams = new URLSearchParams(window.location.search);
  const organizationId = searchParams.get("organizationId") || "";
  const onSubmit: SubmitHandler<Invoice> = (data) => {
    const invoice = createInvoice(credential, data);
    invoice.then((invoice) => {
      if (invoice.link) {
        setInvoiceLink(invoice.link);
      } else if (invoice.message) {
        setInvoiceLink(invoice.message);
      }
    });
  };
  return (
    <Card>
      <HomeButton />
      <Tabs />
      <CardDivider />
      <CardContents>
        <h2>Invoicing</h2>
        <div style={{ maxWidth: "600px" }}>
          To create an invoice, fill in the form below. If you leave the Organization ID field empty, the invoice data
          will be generated for all organizations. If you do not know the organization ID, you can find the organization
          in the <i>All Organizations</i> section, and press the invoice button.
        </div>
        <br />
        <InvoicingForm onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <FormFieldLabel>Organization ID</FormFieldLabel>
            <FormFieldInputContainer>
              <input defaultValue={organizationId} {...register("organizationId", { required: false })} />
            </FormFieldInputContainer>
          </FormField>
          <FormField>
            <FormFieldLabel>From</FormFieldLabel>
            <FormFieldInputContainer>
              <input aria-label="Start date" type="date" {...register("from", { required: true })} />
              {errors.from && <FormFieldError>This field is required</FormFieldError>}
            </FormFieldInputContainer>
          </FormField>
          <FormField>
            <FormFieldLabel>To</FormFieldLabel>
            <FormFieldInputContainer>
              <input aria-label="End date" type="date" {...register("to", { required: true })} />
              {errors.to && <FormFieldError>This field is required</FormFieldError>}
            </FormFieldInputContainer>
          </FormField>

          <Button $type="primary" $size="small" type="submit">
            Submit
          </Button>
        </InvoicingForm>
        <br />
        <Response response={invoiceLink} title={"Link to invoice"} />
      </CardContents>
    </Card>
  );
};
