import type { FC } from "react";
import type { Portfolio, PortfolioChangelog, CreatePortfolioRequest, UpdatePortfolioRequest } from "api/types";

import { useForm, UseFormRegister, FieldErrors } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

import { camelCaseToTitleCase } from "stringFormatters";
import { useState, useEffect } from "react";
import { Card, CardContents, CardDivider } from "components/Card";
import { Form } from "components/Form";
import { FormItem } from "components/FormItem";
import { HomeButton } from "components/HomeButton";
import { InputError } from "components/InputError";
import { Modal, ModalHeader, ModalBody } from "components/Modal";
import Table from "components/Table";
import { Tabs } from "components/Tabs";
import { FormField } from "components/FormField";

import { createPortfolio, getPortfolios, updatePortfolio } from "api/portfolios";
import { useLogin } from "useLogin";

type PortfolioHistoryProps = {
  portfolio: Portfolio;
  errors: FieldErrors<UpdatePortfolioRequest>;
  onClose: () => void;
  register: UseFormRegister<UpdatePortfolioRequest>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

const PortfolioModal: FC<PortfolioHistoryProps> = ({ portfolio, errors, onClose, onSubmit, register }) => {
  return (
    <Modal onClose={onClose} closeable isOpen={true} animate size={"responsive"} role={"dialog"}>
      <ModalHeader>{portfolio.name}</ModalHeader>
      <ModalBody>
        <Card>
          <CardContents>
            <Form onSubmit={onSubmit} buttonText="Update portfolio price">
              <FormItem title={"ID"}>{portfolio.id}</FormItem>
              <FormItem title={"Name"}>{portfolio.name}</FormItem>
              <FormItem title={"Price per ton (cents)"}>
                <FormField
                  type="number"
                  placeholder={portfolio.priceCents.toString()}
                  {...register("averagePriceInEurCents", { required: "Price is required" })}
                />
              </FormItem>
              {errors.averagePriceInEurCents && <InputError>{errors.averagePriceInEurCents.message}</InputError>}
            </Form>
          </CardContents>
        </Card>
        <CardDivider />
        <h3>Price history</h3>
        <Card>
          <Table data={portfolio.history} headerFormatter={camelCaseToTitleCase} emptyMessage={"No changelog"} />
        </Card>
      </ModalBody>
    </Modal>
  );
};

export const Portfolios: FC = () => {
  const [portfolios, setPortfolios] = useState<Portfolio[]>();
  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
  const [curPortfolio, setCurPortfolio] = useState<Portfolio>();
  const credential = useLogin();

  const updateForm = useForm<UpdatePortfolioRequest>({});
  const newForm = useForm<CreatePortfolioRequest>({});

  const onUpdatePortfolio = updateForm.handleSubmit(async (formData) => {
    try {
      if (!curPortfolio || curPortfolio.priceCents === formData.averagePriceInEurCents) {
        return;
      }
      const req: UpdatePortfolioRequest = {
        portfolioId: curPortfolio.id,
        averagePriceInEurCents: Number(formData.averagePriceInEurCents),
      };
      await updatePortfolio(credential, req);
      const portfolios = await getPortfolios(credential);
      setPortfolios(portfolios);
      setCurPortfolio(
        portfolios.find((port) => {
          return curPortfolio.id === port.id;
        })
      );
      toast.success("Portfolio updated");
    } catch (error: any) {
      toast.error(error.message || "An error occured");
      console.error(error);
    }
  });

  const onCreatePortfolio = newForm.handleSubmit(async (formData) => {
    try {
      const req: CreatePortfolioRequest = {
        name: formData.name,
        averagePriceInEurCents: Number(formData.averagePriceInEurCents),
      };
      await createPortfolio(credential, req);
      const portfolios = await getPortfolios(credential);
      setPortfolios(portfolios);
      toast.success("Portfolio created");
    } catch (error: any) {
      toast.error(error.message || "An error occured");
      console.error(error);
    }
  });

  useEffect(() => {
    const fetchPortfolios = async () => {
      const portfolios = await getPortfolios(credential);
      setPortfolios(portfolios);
    };
    fetchPortfolios();
  }, [credential]);

  const onPortfolioRowClicked = (record: Record<string, any>) => {
    return () => {
      setCurPortfolio(record as Portfolio);
      setHistoryModalOpen(true);
    };
  };

  const formatCell = (key: string, value: any) => {
    if (key === "history") {
      return (value as PortfolioChangelog[]).length === 0 ? "N/A" : "Click to view";
    }
    return value;
  };

  return (
    <Card>
      <HomeButton />
      <Tabs />
      <CardDivider />

      <ToastContainer autoClose={5000} position="top-center" />

      <CardContents
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "40%",
        }}
      >
        <h3>New portfolio</h3>
        <Form onSubmit={onCreatePortfolio} buttonText="Create portfolio">
          <FormItem title={"Name"}>
            <FormField {...newForm.register("name", { required: "Name is required" })} />
          </FormItem>
          {newForm.formState.errors.name && <InputError>{newForm.formState.errors.name.message}</InputError>}
          <FormItem title={"Price per ton (cents)"}>
            <FormField
              type="number"
              placeholder="2000"
              {...newForm.register("averagePriceInEurCents", { required: "Price is required" })}
            />
          </FormItem>
          {newForm.formState.errors.averagePriceInEurCents && (
            <InputError>{newForm.formState.errors.averagePriceInEurCents.message}</InputError>
          )}
        </Form>
      </CardContents>

      <Table
        data={portfolios || []}
        omitColumns={["id"]}
        headerFormatter={camelCaseToTitleCase}
        cellFormatter={formatCell}
        emptyMessage={"No portfolios"}
        onBodyClick={onPortfolioRowClicked}
      />

      {historyModalOpen && curPortfolio && (
        <PortfolioModal
          portfolio={curPortfolio}
          errors={updateForm.formState.errors}
          onClose={() => {
            setHistoryModalOpen(false);
          }}
          onSubmit={onUpdatePortfolio}
          register={updateForm.register}
        />
      )}
    </Card>
  );
};
