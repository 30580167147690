import { Form } from "components/Form";
import { FormItem } from "components/FormItem";
import { Modal, ModalBody } from "components/Modal";
import { FormField } from "components/FormField";
import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputError } from "components/InputError";
import { useLogin } from "useLogin";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { Button, Card } from "@southpolecarbon/climateclick-ui";
import { ToastContainer, toast } from "react-toastify";
import { createImport } from "api/imports";

interface SingleImportModal {
  organizationId: string;
  fetchImports: () => Promise<void>;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface RegisterProductFormData {
  id: string;
  name: string;
  category: string;
  url: string;
  priceInCents: number;
  weightInGrams: number;
  currency: string;
  description: string;
  brand: string;
  articleIDType: string;
  articleID: string;
  language: string;
  imageUrls: Array<string>;
  materials: Array<string>;
}

const notify = (ok: boolean) => {
  if (ok) {
    toast.success("Product import sent", {
      position: "top-center",
    });
  } else {
    toast.error("Error importing product", {
      position: "bottom-center",
    });
  }
};
export const SingleImportModal: FC<SingleImportModal> = ({ organizationId, fetchImports, setModalState }) => {
  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm();
  const credentials = useLogin();
  const onSubmit: SubmitHandler<any> = async (data) => {
    const entries = new Map(Object.entries(data));
    const headers: string[] = [];
    const values: string | any[] = [];
    const materials: string[] = [];
    const imageUrls: string[] = [];
    let materialsString = "";
    let imageUrlsString = "";

    entries.forEach((v, k) => {
      headers.push(k);
      if (k === "imageUrls") {
        const imageUrlsValues = v as Array<any>;
        imageUrlsValues.forEach((value, key) => {
          imageUrls[key] = value;
        });
        imageUrlsString = imageUrls.join(";");
        values.push(imageUrlsString);
        return;
      }
      if (k === "materials") {
        const materialValues = v as Array<any>;
        materialValues.forEach((value, key) => {
          materials[key] = value.name + "/" + value.percentage;
        });
        materialsString = materials.join(";");
        values.push(materialsString);
        return;
      }

      values.push(v);
    });

    const fileString = headers.join(",") + "\r\n" + values.join(",");
    createImport(credentials, organizationId, fileString)
      .then((_) => {
        notify(true);
        setModalState(false);
        fetchImports();
      })
      .catch((error) => {
        console.error(error);
        notify(false);
      });
  };

  const [indexeImageUrls, setImageUrlsIndexes] = useState(Array<number>);
  const [counterImageUrls, setImageUrlsCounter] = useState(0);

  const [indexesMaterials, setMaterialsIndexes] = useState(Array<number>);
  const [counterMaterials, setMaterialsCounter] = useState(0);

  const addImageUrl = () => {
    setImageUrlsIndexes((prevIndexes) => [...prevIndexes, counterImageUrls]);
    setImageUrlsCounter((prevCounter) => prevCounter + 1);
  };

  const removeImageUrl = (index: number) => () => {
    setImageUrlsIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
    setImageUrlsCounter((prevCounter) => prevCounter - 1);
  };
  const clearImages = () => {
    setImageUrlsIndexes([]);
    unregister("imageUrls");
  };

  const addMaterial = () => {
    setMaterialsIndexes((prevIndexes) => [...prevIndexes, counterMaterials]);
    setMaterialsCounter((prevCounter) => prevCounter + 1);
  };

  const removeMaterial = (index: number) => () => {
    setMaterialsIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
    setMaterialsCounter((prevCounter) => prevCounter - 1);
  };
  const clearMaterials = () => {
    setMaterialsIndexes([]);
    unregister("materials");
  };
  return (
    <Modal
      onClose={() => setModalState(false)}
      closeable
      isOpen
      animate
      autoFocus
      size={"responsive"}
      role={"dialog"}
      overrides={{
        Dialog: {
          style: {
            width: "50%",
            fontWeight: 500,
          },
        },
      }}
    >
      <ModalBody>
        <ToastContainer autoClose={8000} />
        <h2>Product Import</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem title={"ID*"} description={""}>
            <FormField placeholder="123AAA" {...register(`id`, { required: true })} />
            {errors.id && <InputError>ID is required</InputError>}
          </FormItem>
          <FormItem title={"Product name"} description={""}>
            <FormField placeholder="Sonos One SL Speaker Black" {...register(`name`, { required: true })} />
            {errors.name && <InputError>Name is required</InputError>}
          </FormItem>
          <FormItem title={"Category*"} description={""}>
            <FormField
              placeholder="Electronics > Audio > Audio Players & Recorders"
              {...register(`category`, { required: true })}
            />
            {errors.category && <InputError>Category is required</InputError>}
          </FormItem>
          <FormItem
            title={"Url*"}
            description={
              "Product url is used for validating the source of the product, ideally we would like to have a reference to the product. However, if you for whatever reason do not want to provide valid product url, you can use http://example.com"
            }
          >
            <FormField placeholder="https://www.sonos.com/en/shop/one-sl" {...register(`url`, { required: true })} />
            {errors.url && <InputError>Url is required</InputError>}
          </FormItem>
          <FormItem title={"Brand"} description="">
            <FormField placeholder="Sonos" {...register(`brand`, { required: false })} />
          </FormItem>
          <FormItem
            title={"Price (in cents)*"}
            description={
              "Price of the product can vary, it is relevant for spend based estimate so if we aim for A4 and higher estimate, this value can be assigned at random or from a competitor"
            }
          >
            <FormField placeholder="18500" {...register(`priceInCents`, { required: true })} />
            {errors.url && <InputError>Price is required</InputError>}
          </FormItem>
          <FormItem title={"Currency*"} description="">
            <FormField placeholder="EUR" {...register(`currency`, { required: false })} />
            {errors.url && <InputError>Currency is required</InputError>}
          </FormItem>
          <FormItem
            title={"Weight (in grams)"}
            description={
              "Relevant for calculating total footprint based on the weights, if not available, choose a value from a competitor or other realistic number of choice"
            }
          >
            <FormField placeholder="1850" {...register(`weightInGrams`, { required: false })} />
          </FormItem>

          <FormItem title={"Description"} description="">
            <TextArea
              placeholder="Featuring next-gen acoustics and new levels of connectivity,
              Era 100 transforms any room with the finely tuned stereo sound and rich bass your music deserves."
              {...register(`description`, { required: false })}
            />
          </FormItem>
          <FormItem title={"Article Type"} description="EAN, UPC and ISBN">
            <FormField placeholder="EAN" {...register(`articleIDType`, { required: false })} />
          </FormItem>
          <FormItem
            title={"Article ID"}
            description="refers to one of the existing stardards for uniquely identifying products, we support: EAN, UPC and ISBN"
          >
            <FormField placeholder="1123234234234" {...register(`articleID`, { required: false })} />
          </FormItem>
          <FormItem title={"Language"} description="">
            <FormField placeholder="EN" {...register(`language`, { required: false })} />
          </FormItem>

          <FormItem
            title={"Materials"}
            description=" You can enter any material name that is available and percentage if it is known, we will try to match
              those with data we have. In case you expected A4 match but got less than that, please reach out to
              engineering team"
          >
            {indexesMaterials.map((index) => {
              const fieldName = `materials[${index}]`;
              return (
                <Card key={index} style={{ width: "100%" }}>
                  <FormItem title={"Material name"} description="">
                    <FormField placeholder="Plastic" {...register(`${fieldName}.name`, { required: false })} />
                  </FormItem>

                  <FormItem title={"Percentage"} description="">
                    <FormField placeholder="20" {...register(`${fieldName}.percentage`, { required: false })} />
                  </FormItem>
                  <Button
                    style={{
                      marginTop: "10px",
                    }}
                    onClick={removeMaterial(index)}
                  >
                    Remove
                  </Button>
                </Card>
              );
            })}
            <Button onClick={addMaterial}>Add Materials</Button>
            <Button onClick={clearMaterials}>Clear Materials</Button>
          </FormItem>

          <FormItem title={"Images"} description="">
            {indexeImageUrls.map((index) => {
              const fieldName = `imageUrls[${index}]`;
              return (
                <Card key={index} style={{ width: "100%" }}>
                  <FormItem title={"Image Url"} description="">
                    <FormField placeholder="https://sonos.com/img1" {...register(fieldName, { required: false })} />
                  </FormItem>
                  <Button
                    style={{
                      marginTop: "10px",
                    }}
                    onClick={removeImageUrl(index)}
                  >
                    Remove
                  </Button>
                </Card>
              );
            })}
            <Button onClick={addImageUrl}>Add Image Url</Button>
            <Button onClick={clearImages}>Clear Images</Button>
          </FormItem>
        </Form>
      </ModalBody>
    </Modal>
  );
};
