import type { SectorialScope } from "api/types";

import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { useLogin } from "useLogin";
import { addSectorialScopes, getSectorialScopes } from "api/sectorialScopes";

import { Card, CardContents, CardDivider } from "components/Card";
import { HomeButton } from "components/HomeButton";
import { Tabs } from "components/Tabs";
import Table from "components/Table";
import { FormField, FormFieldLabel, SectorialScopeFrom } from "./sectorialScopes.styled";
import Select from "react-select";

import { Button } from "@southpolecarbon/climateclick-ui";
import { camelCaseToTitleCase } from "stringFormatters";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getOrganizations } from "api/organizations";
import { Organization } from "features/cms/cms.types";

type SelectableValue = {
  label: string;
  value: string;
};

type OrganizationSectorialScopes = {
  organizationId: string;
  sectorialScopes: string[];
};

export const SectorialScopes = () => {
  const { handleSubmit } = useForm<OrganizationSectorialScopes>();
  const credential = useLogin();
  const [sectorialScopes, setSectorialScopes] = useState<SectorialScope[]>([]);
  const [values, setValues] = useState<SelectableValue[]>([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState("");
  const [organizations, setOrganizations] = useState<Organization[] | undefined>([]);

  const notify = (ok: boolean) => {
    if (ok) {
      toast.success("Sectorial Scopes added", {
        position: "top-center",
      });
    } else {
      toast.error("Error adding scopes. Contact us!", {
        position: "top-center",
      });
    }
  };
  const handleSelectChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    const main = async () => {
      const storedSectorialScopes = sessionStorage.getItem("sectorialScopes");
      if (storedSectorialScopes) {
        setSectorialScopes(JSON.parse(storedSectorialScopes));
        const sectorialScopesValues = JSON.parse(storedSectorialScopes).map((project: { id: any; name: any }) => ({
          value: project.id,
          label: project.name,
        }));
        setValues(sectorialScopesValues);
      } else {
        const sectorialScopes = await getSectorialScopes(credential);
        if (sectorialScopes.length === 0) {
          return;
        }
        sessionStorage.setItem("sectorialScopes", JSON.stringify(sectorialScopes));
        setSectorialScopes(sectorialScopes);
        const sectorialScopesValues = sectorialScopes.map((project) => ({
          value: project.id,
          label: project.name,
        }));
        setValues(sectorialScopesValues);
      }
    };
    main();
  }, [credential]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getOrganizations(credential);
        setOrganizations(data || []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const onSubmit: SubmitHandler<OrganizationSectorialScopes> = async (_) => {
    const values = selectedOptions.map((option: SelectableValue) => option.value);
    try {
      await addSectorialScopes(credential, organizationSelected, values);
      notify(true);
    } catch (e) {
      console.error(e);
      notify(false);
    }
  };

  return (
    <Card>
      <HomeButton />
      <Tabs />
      <CardDivider />
      <CardContents>
        <h2>Sectorial Scopes</h2>
        <div style={{ marginBottom: "20px" }}>
          Assign sectorial scopes to organizations following the steps:
          <ul>
            <li>Select an organization and the targeted sectorial scopes to assign</li>
          </ul>
        </div>

        <SectorialScopeFrom onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <FormFieldLabel>
              <strong>Organization:</strong>{" "}
            </FormFieldLabel>
            <Select
              styles={{
                container: (base) => ({
                  ...base,
                  width: "400px",
                }),
              }}
              placeholder="Filter by Organizations"
              onChange={(e) => setOrganizationSelected(e?.value || "")}
              options={organizations?.map((org) => ({ label: org?.Name, value: org?.OrganizationId }))}
              required={true}
            />
          </FormField>

          <FormField>
            <FormFieldLabel>
              <strong>Sectorial scopes:</strong>{" "}
            </FormFieldLabel>

            <Select
              styles={{
                container: (base) => ({
                  ...base,
                  width: "400px",
                }),
              }}
              isMulti
              options={values}
              onChange={handleSelectChange}
              required={true}
            />
          </FormField>
          <Button $type="primary" $size="small" type="submit">
            Assign
          </Button>
          <ToastContainer autoClose={8000} />
        </SectorialScopeFrom>
      </CardContents>

      <Table
        data={sectorialScopes || []}
        omitColumns={["id", "image", "createdAt"]}
        headerFormatter={camelCaseToTitleCase}
        emptyMessage={"No sectorial scopes found yet"}
      />
    </Card>
  );
};
